import React from 'react';
import { string, node, oneOf, shape, number, func } from 'prop-types';

import interpolator from '@components/styled-label/components/interpolator';
import StyledBase from '@components/styled-label/components/styled-base';
import { APP_PREFIX } from '@constants/commons';

const namespace = `${APP_PREFIX}-styled-label`;

const DEFAULT_AS = 'p';

const StyledLabel = ({
  as = DEFAULT_AS,
  className,
  classNameInterpolator,
  dataModel,
  iconFactory,
  children,
  ...forwardProps
}) => {
  const { text, accessibilityText, styles, values } = dataModel;

  return (
    <StyledBase
      text={
        values
          ? interpolator(text, values, iconFactory, classNameInterpolator)
          : text
      }
      {...{
        as,
        ...styles,
        accessibilityText,
        ...forwardProps,
        className: className ? `${namespace} ${className}` : namespace,
      }}
    >
      {' '}
      {children}
    </StyledBase>
  );
};

export default StyledLabel;

StyledLabel.propTypes = {
  as: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li', 'a', 'del']),
  children: node,
  className: string,
  classNameInterpolator: string,
  dataModel: shape({
    alignment: oneOf(['center', 'left', 'right']),
    margin: shape({
      top: number,
      bottom: number,
      left: number,
      right: number,
    }),
    color: string,
    font_family: string,
    font_size: string,
    accessibilityText: string,
  }),
  iconFactory: func,
};
