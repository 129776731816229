import React from 'react';
import { shape, arrayOf, string, bool } from 'prop-types';

import {
  RecommendationsList,
  RecommendationsListSingle,
} from '@recommendations-fe/list';

import IconFactory from '@components/recommendations/icons/icon-factory';
import { CARD_TYPE } from '@constants/recommendations';
import { APP_PREFIX, DEVICE_TYPE } from '@constants/commons';

const namespace = `${APP_PREFIX}-list-single`;

const RecommendationsFeSingle = ({
  recommendation_data,
  lazy = false,
  country,
  cardType,
  deviceType,
  ...rest
}) => (
  <section className={`${namespace}`}>
    <RecommendationsList
      IconFactory={IconFactory}
      ListComponent={RecommendationsListSingle}
      listType={CARD_TYPE.SINGLE}
      hideRebates
      apiresponse={recommendation_data}
      preload={!lazy}
      fetchPriority={!lazy && 'high'}
      criticalImages={1}
      lazy={lazy}
      deviceType={deviceType}
      {...rest}
      cardType={cardType}
      contextProps={{
        thousandSeparator: country?.thousands_separator,
        decimalSeparator: country?.decimal_separator,
      }}
    />
  </section>
);

RecommendationsFeSingle.propTypes = {
  id: string,
  recommendation_data: shape({
    items: arrayOf(string),
  }),
  deviceType: string,
  lazy: bool,
  country: shape({}),
  cardType: string,
};

RecommendationsFeSingle.defaultProps = {
  cardType: CARD_TYPE.SINGLE,
  deviceType: DEVICE_TYPE.MOBILE,
};

export default RecommendationsFeSingle;
