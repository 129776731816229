export const LIST = {
  priceSizeOverride: {
    price: 24,
    discount: 12,
    installments: 12,
    original: 12,
  },
};

export const CARD_TYPE = {
  DEFAULT: 'default',
  GRID: 'grid',
  LIST: 'list',
  SINGLE: 'single',
};

export const DEFAULT_SLIDE_PER_VIEW = 5;
