import classnames from 'classnames';

const DEFAULT_FAMILY = false;

const namespace = 'font-family';

const fontFamily = (family, className) => {
  const familyName =
    typeof family === 'string' ? family : family?.font_family || DEFAULT_FAMILY;

  return classnames({ [`${namespace}-${familyName}`]: familyName }, className);
};

export default fontFamily;
