import React from 'react';
import { element, string } from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { client } = this.props;
    const { newrelic } = window || {};

    if (newrelic) {
      newrelic.noticeError(error, {
        error: `Error Boundary - Landing: ${client}'`,
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo.componentStack,
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? null : children;
  }
}

ErrorBoundary.propTypes = {
  children: element.isRequired,
  client: string,
};

export default ErrorBoundary;
