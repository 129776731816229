/* istanbul ignore file */
import React from 'react';
import { string, shape, bool, func } from 'prop-types';

import classNames from 'classnames';
import RecommendationsCarouselByType from '@recommendations-fe/carousel-by-type';
import {
  RecommendationsListWithoutContainer,
  RecommendationsListDefault,
  RecommendationsListGrid,
} from '@recommendations-fe/list';
import {
  RecommendationsCardHorizontalTitleFirst,
  RecommendationsCardVerticalTitleFirst,
  RecommendationsCardVertical,
} from '@recommendations-fe/card';

import IconFactory from '@components/recommendations/icons/icon-factory';
import { APP_PREFIX } from '@constants/commons';
import { CARD_TYPE } from '@constants/recommendations';

const defaultCard = {
  'more-like-this-recommendations': RecommendationsCardVerticalTitleFirst,
};

// Map for ByType
const carouselsMap = {
  list: ({ Card, ...props }) => (
    <RecommendationsListWithoutContainer
      Card={Card || RecommendationsCardHorizontalTitleFirst}
      ListComponent={RecommendationsListDefault}
      listType={CARD_TYPE.DEFAULT}
      {...props}
    />
  ),
  grid: ({ id, Card, ...rest }) => (
    <RecommendationsListWithoutContainer
      Card={Card || (defaultCard[id] ?? RecommendationsCardVertical)}
      ListComponent={RecommendationsListGrid}
      listType={CARD_TYPE.GRID}
      id={id}
      {...rest}
    />
  ),
  default: ({ Card, ...props }) => (
    <RecommendationsListWithoutContainer
      Card={Card || RecommendationsCardHorizontalTitleFirst}
      ListComponent={RecommendationsListDefault}
      listType={CARD_TYPE.DEFAULT}
      {...props}
    />
  ),
};

// get Type in case carousel_type is not coming
const getType = ({ id }) => {
  const idType = id?.split('-')?.pop();

  if (idType === CARD_TYPE.GRID || idType === CARD_TYPE.LIST) {
    return idType;
  }

  return CARD_TYPE.LIST;
};

const namespace = `${APP_PREFIX}-carousel-by-type`;

const RecommendationsWrapper = ({
  className,
  carousel_type,
  recommendation_data,
  title_tag,
  deviceType = 'mobile',
  handleSaveRenderTrack = null,
  shouldTrackOnPrint,
  shouldTrackOnView,
  is_polycard = true,
  lazy = false,
  ...rest
}) => {
  const carouselType =
    carousel_type ??
    recommendation_data?.recommendation_info?.carousel_type ??
    getType(rest);

  const cardType =
    carouselType === CARD_TYPE.GRID ? CARD_TYPE.GRID : CARD_TYPE.LIST;

  return (
    <section className={classNames(namespace, className)}>
      <RecommendationsCarouselByType
        IconFactory={IconFactory}
        carousels={carouselsMap}
        carouselType={carouselType}
        cardType={cardType}
        apiresponse={recommendation_data}
        titleTag={title_tag}
        deviceType={deviceType}
        onTrack={handleSaveRenderTrack}
        shouldTrackOnPrint={shouldTrackOnPrint}
        shouldTrackOnView={shouldTrackOnView}
        is_polycard={is_polycard}
        lazy={lazy}
        hideRebates
        {...rest}
      />
    </section>
  );
};

RecommendationsWrapper.propTypes = {
  className: string,
  recommendation_data: shape({}),
  carousel_type: string,
  is_polycard: bool,
  deviceType: string,
  lazy: bool,
  handleSaveRenderTrack: func,
  shouldTrackOnPrint: bool,
  shouldTrackOnView: bool,
  title_tag: shape({}),
};

export default RecommendationsWrapper;
