import { shape, string, oneOf, boolean } from 'prop-types';
import React, { useEffect } from 'react';

import { Head } from 'nordic/head';

import {
  CARD_FEATURED,
  DENOUNCE_PROFILE,
  HEADER_PROFILE,
  SUBTITLE,
  TABS_RECOS,
} from '@constants/components-ids';
import { arrayFind } from '@utils/array';
import StyledLabel from '@components/styled-label';
import RecommendationsProductSocial from '@pages/social/ui-components/recommendations-product-social';
import useTabsData from '@components/tabs/hooks/useTabsData';
import Tabs from '@components/tabs';
import { trackView } from '@services/tracking';

import DenounceProfile from '../denounce';
import { HeaderProfile } from '../header';

const OPEN_GRAPH = 'og:';
const TWITTER = 'twitter:';
const SocialProfile = ({
  country,
  data,
  deviceType,
  headerProps,
  namespace,
  query,
  isWebView,
  melidataSocial,
  metaTags,
}) => {
  const { components } = data;
  const denounceProfile = arrayFind(components, DENOUNCE_PROFILE);
  const headerProfile = arrayFind(components, HEADER_PROFILE);
  const subtitleData = arrayFind(components, SUBTITLE);
  const tiggerCard = arrayFind(components, CARD_FEATURED);
  const queryString = new URLSearchParams(query).toString();
  const trackingId = melidataSocial?.event_data?.tracking_id;
  const source = melidataSocial?.event_data?.source;
  const { dataTabs } = useTabsData({
    queryString,
    intialValue: arrayFind(components, TABS_RECOS),
    trackingId,
    source,
  });

  const showHeaderProfile =
    headerProfile?.header?.track && headerProfile?.header?.tracking_data;
  const showDenounceProfile =
    denounceProfile && (denounceProfile.propertie || denounceProfile.property);
  const dataDenounceProfile =
    denounceProfile.propertie || denounceProfile.property;

  const {
    tracking_data: trackingData,
    track: tracks,
    ...rest
  } = headerProfile?.header || {};

  const transformedHeader = {
    ...rest,
    tracks,
    trackingData,
  };

  const sortMetaData = (metaData) => {
    const normalMeta = metaData.filter(
      ({ type }) => !type.startsWith(OPEN_GRAPH) && !type.startsWith(TWITTER),
    );
    const openGraphMeta = metaData.filter(({ type }) =>
      type.startsWith(OPEN_GRAPH),
    );
    const twitterMeta = metaData.filter(({ type }) => type.startsWith(TWITTER));

    return [...normalMeta, ...openGraphMeta, ...twitterMeta];
  };

  useEffect(() => {
    trackView(melidataSocial);
  }, [melidataSocial]);

  return (
    <div className={`${namespace}_container`}>
      {metaTags?.length > 0 && (
        <Head>
          {sortMetaData(metaTags).map(({ type, content }) => (
            <meta
              key={`${type}-${content}`}
              {...(type.startsWith(OPEN_GRAPH)
                ? { property: type }
                : { name: type })}
              content={content}
            />
          ))}
        </Head>
      )}
      {showHeaderProfile && (
        <HeaderProfile
          key={`header-profile-${deviceType}`}
          header={{ ...transformedHeader, ...headerProps }}
          namespace={namespace}
        />
      )}
      <div className={`${namespace}_content`}>
        {subtitleData && (
          <div className={`${namespace}_title`}>
            <StyledLabel
              as="h2"
              dataModel={{
                ...subtitleData.subtitle.label,
                values: subtitleData.subtitle.values,
              }}
            />
          </div>
        )}
        <RecommendationsProductSocial
          components={components}
          deviceType={deviceType}
          namespace={namespace}
          country={country}
        />
        {tiggerCard && <div className={`${namespace}-line`} />}
        {dataTabs && (
          <Tabs
            tabs={dataTabs.tabs}
            deviceType={deviceType}
            country={country}
            swipeable
            isWebView={isWebView}
          />
        )}
        {showDenounceProfile && (
          <DenounceProfile
            {...dataDenounceProfile}
            deviceType={deviceType}
            namespace={namespace}
          />
        )}
      </div>
    </div>
  );
};

SocialProfile.propTypes = {
  country: shape({}).isRequired,
  data: shape({}).isRequired,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  headerProps: shape({}).isRequired,
  namespace: string,
  melidataSocial: shape({}),
  query: string,
  isWebView: boolean,
  metaTags: shape({}),
};

export default SocialProfile;
