import { useEffect, useState } from 'react';

import { RestClient } from 'nordic/restclient';

const restclient = RestClient({
  timeout: 5000,
  baseURL: '/api/recos-landings',
});

const useTabsData = ({
  queryString,
  intialValue,
  trackingId = null,
  source = null,
  isNewLandingFlow = false,
}) => {
  const [dataTabs, setDataTabs] = useState(intialValue);
  const { deferred_req: deferredReq } = intialValue || {};

  useEffect(() => {
    if (deferredReq) {
      const paramsString = deferredReq[0]?.params
        ?.map(({ param, value }) => `${param}=${value}`)
        .join('&');
      const tabIdsString = deferredReq[0]?.tab_ids?.join(',');

      let url = `/tabs?${queryString}&${paramsString}&ids=${tabIdsString}`;

      if (trackingId) {
        url = `${url}&tracking_id=${trackingId}`;
      }

      if (source) {
        url = `${url}&source=${source}`;
      }

      const params = {
        isNewLandingFlow,
      };

      restclient
        .get(url, { params })
        .then(({ data: tabsInfo }) => {
          if (tabsInfo?.tabs_content) {
            setDataTabs((prevState) => {
              const validateComponent = prevState.tabs.map((componente) => {
                const idTab = componente.id;
                const tabContent = tabsInfo.tabs_content.find(
                  (tab) => tab.id === idTab,
                );

                return tabContent
                  ? { ...componente, components: tabContent.components }
                  : componente;
              });

              return { ...prevState, tabs: validateComponent };
            });
          }
        })
        .catch(() => {});
    }
  }, [deferredReq, queryString, trackingId, source, isNewLandingFlow]);

  return { dataTabs };
};

export default useTabsData;
