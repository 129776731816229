import React, { useState } from 'react';

const useRenderTabTrack = () => {
  const [isRenderTabTrack, setIsRenderTabTrack] = useState([]);

  const isShouldTrack = (tabId, trackType) => {
    if (isRenderTabTrack.length === 0) {
      return true;
    }

    return !isRenderTabTrack.some(
      ({ tabId: id, trackType: type }) => id === tabId && type === trackType,
    );
  };

  const handleSaveRenderTrack = (tabId, trackType) => {
    setIsRenderTabTrack((prevState) => {
      const exists = prevState.some(
        ({ tabId: id, trackType: type }) => id === tabId && type === trackType,
      );

      return !exists && tabId && trackType
        ? [...prevState, { tabId, trackType }]
        : prevState;
    });
  };

  return { handleSaveRenderTrack, isShouldTrack };
};

export default useRenderTabTrack;
