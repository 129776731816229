import React from 'react';
import { string } from 'prop-types';

import { loadable } from 'nordic/lazy';

import { EMPTY_STATE, ERROR_HANDLED } from '@constants/components-ids';

const TechnicalError = loadable(async () =>
  import('@components/technical-error'),
);

const EmptyState = loadable(async () => import('@components/empty-state'));

const RenderErrorOrEmptyState = ({ type, ...props }) => (
  <>
    {type === ERROR_HANDLED && <TechnicalError {...props} />}
    {type === EMPTY_STATE && <EmptyState {...props} />}
  </>
);

RenderErrorOrEmptyState.propTypes = {
  type: string,
};

export default RenderErrorOrEmptyState;
