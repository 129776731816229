/* istanbul ignore file */

import React, { useEffect, useState, useRef } from 'react';
import { oneOf, arrayOf, shape, boolean } from 'prop-types';

import classnames from 'classnames';
import { Tab, Tabs, TabContent } from '@andes/tabs';

import RecosCarouselByType from '@components/recommendations/carousel-by-type';
import RecosCarouselFree from '@components/recommendations/carousel-free';
import EmptyStateContent from '@components/empty-state/content';
import RecosTabEmptyStateIcon from '@components/icons/tab-empty-state';
import { LIST } from '@constants/recommendations';
import { PADS } from '@constants/clients';
import { APP_PREFIX } from '@constants/commons';

import useRenderTabTrack from './hooks/useRenderTabTrack';

const namespace = `${APP_PREFIX}-tabs`;

const TRACK = {
  PRINT: 'print',
  VIEW: 'view',
};

const TabsWrapper = ({
  tabs,
  deviceType,
  country,
  swipeable = false,
  isWebView,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabsRef = useRef(null);
  const { handleSaveRenderTrack, isShouldTrack } = useRenderTabTrack();

  const handleChange = (event) => {
    if (swipeable && tabsRef.current) {
      const nextTab = event.target.value;

      if (selectedTab > nextTab && nextTab === 0) {
        tabsRef.current.classList.add(`${namespace}-animation-left-to-first`);
      } else if (selectedTab > nextTab) {
        tabsRef.current.classList.add(`${namespace}-animation-left`);
      } else {
        tabsRef.current.classList.add(`${namespace}-animation-right`);
      }

      setSelectedTab(event.target.value);
    }
  };

  const handleAnimationEnd = () => {
    if (tabsRef.current) {
      tabsRef.current.classList.remove(
        `${namespace}-animation-left-to-first`,
        `${namespace}-animation-left`,
        `${namespace}-animation-right`,
      );
    }
  };

  useEffect(() => {
    const tabsRefCurrent = tabsRef.current;

    if (swipeable && tabsRefCurrent) {
      tabsRefCurrent.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (swipeable && tabsRefCurrent) {
        tabsRefCurrent.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [swipeable]);

  const isRecommendationsTogetherCarousel = (componentId) =>
    componentId === 'carousels';
  const isRecommendationsTogetherList = (idComponent) =>
    idComponent === 'list-recommendations';

  const handleOnClick = (event, url, id, component) => {
    if (!isWebView) {
      return;
    }

    if (
      isWebView &&
      window?.MobileWebKit &&
      component?.client?.toLowerCase().includes(PADS)
    ) {
      event.preventDefault();

      const items =
        component?.recommendation_data?.recommendation_info?.polycards;
      const item = (items ?? []).find((key) => key.metadata.id === id);

      fetch(item?.metadata?.url_click, {
        mode: 'cors',
      })
        .then(async () => {
          await window.MobileWebKit.navigation.push(url);
        })
        .catch(async () => {
          await window.MobileWebKit.navigation.push(url);
        });
    }
  };

  return (
    <div className={namespace}>
      <Tabs
        id="tabs"
        ref={tabsRef}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleChange}
        swipeable={swipeable}
        behavior="fullWidth"
      >
        {tabs?.map(({ id, title, components }) => (
          <Tab key={id} title={title} className={`${namespace}-titles`}>
            <TabContent
              className={classnames(`${namespace}-content`, {
                [`${namespace}-content-swipeable`]: swipeable,
              })}
            >
              <div id={id}>
                {components?.map(
                  (component) =>
                    component && (
                      <div key={`${id}-${component.id}`}>
                        {component.id === 'recos-empty-subtitle' && (
                          <EmptyStateContent
                            components={components}
                            emptyStateIcon={RecosTabEmptyStateIcon}
                          />
                        )}

                        {(component?.id !== 'recos-empty-subtitle' ||
                          component?.id !== 'recos-empty-title') &&
                          !isRecommendationsTogetherCarousel(component?.id) &&
                          !isRecommendationsTogetherList(component?.id) && (
                            <div className={`${namespace}-component`}>
                              <RecosCarouselByType
                                key={component?.id}
                                hideRebates={false}
                                priceSizeOverride={LIST.priceSizeOverride}
                                deviceType={deviceType}
                                {...component}
                                lazy={false}
                                country={country}
                                handleSaveRenderTrack={handleSaveRenderTrack}
                                shouldTrackOnPrint={
                                  isShouldTrack
                                    ? isShouldTrack(component?.id, TRACK.PRINT)
                                    : false
                                }
                                shouldTrackOnView={
                                  isShouldTrack
                                    ? isShouldTrack(component?.id, TRACK.VIEW)
                                    : false
                                }
                                cardProps={{
                                  onClick: (event, { url, id: idItem }) => {
                                    handleOnClick(
                                      event,
                                      url,
                                      idItem,
                                      component,
                                    );
                                  },
                                }}
                              />
                            </div>
                          )}
                        {isRecommendationsTogetherCarousel(component?.id) &&
                          component?.carousels.map((carousel) => (
                            // eslint-disable-next-line react/jsx-key
                            <div className={`${namespace}-carousels`}>
                              <RecosCarouselFree
                                hideRebates={false}
                                deviceType={deviceType}
                                country={country}
                                {...carousel}
                                lazy={false}
                                compact
                                handleSaveRenderTrack={handleSaveRenderTrack}
                                shouldTrackOnPrint={
                                  isShouldTrack
                                    ? isShouldTrack(carousel?.id, TRACK.PRINT)
                                    : false
                                }
                                shouldTrackOnView={
                                  isShouldTrack
                                    ? isShouldTrack(carousel?.id, TRACK.VIEW)
                                    : false
                                }
                              />
                            </div>
                          ))}
                        {isRecommendationsTogetherList(component?.id) &&
                          component?.list_recommendations.map((list) => (
                            // eslint-disable-next-line react/jsx-key
                            <div className={`${namespace}-list`}>
                              <RecosCarouselByType
                                hideRebates={false}
                                deviceType={deviceType}
                                country={country}
                                {...list}
                                lazy={false}
                                compact
                                handleSaveRenderTrack={handleSaveRenderTrack}
                                shouldTrackOnPrint={
                                  isShouldTrack
                                    ? isShouldTrack(list?.id, TRACK.PRINT)
                                    : false
                                }
                                shouldTrackOnView={
                                  isShouldTrack
                                    ? isShouldTrack(list?.id, TRACK.VIEW)
                                    : false
                                }
                                cardProps={{
                                  onClick: (event, { url, id: idItem }) => {
                                    handleOnClick(
                                      event,
                                      url,
                                      idItem,
                                      component,
                                    );
                                  },
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    ),
                )}
              </div>
            </TabContent>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

TabsWrapper.propTypes = {
  tabs: arrayOf(shape({})),
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  swipeable: boolean,
  country: shape({}),
  isWebView: boolean,
};

export default TabsWrapper;
