import React from 'react';
import { bool, oneOf, shape } from 'prop-types';

import env from 'nordic/env';
import countryConfig from 'nordic/country-config';
import classnames from 'classnames';

import LandingWrapper from '@components/landing-wrapper';
import { SOCIAL } from '@constants/clients';
import { APP_PREFIX, DEVICE_TYPE } from '@constants/commons';
import { ERROR_HANDLED, EMPTY_STATE } from '@constants/components-ids';
import * as recosService from '@services/landing';
import { getDomain } from '@utils/get-domain';
import { getTrackView } from '@utils/melidata';
import RenderErrorOrEmptyState from '@utils/renderErrorOrEmptyState';

import SocialProfile from '../ui-components/profile';

const { NODE_ENV } = env;

const SocialLanding = ({
  country,
  data,
  deviceType,
  headerProps,
  isWebView,
  query,
  melidataSocial,
  metaTags,
  ...props
}) => {
  const namespace =
    deviceType === 'desktop' ? 'social-desktop' : 'social-mobile';

  if (data?.type === ERROR_HANDLED || data?.type === EMPTY_STATE) {
    return <RenderErrorOrEmptyState type={data.type} {...props} />;
  }

  return (
    <LandingWrapper
      namespace={namespace}
      className={classnames({ webview: isWebView })}
      country={country}
      isWebView={isWebView}
    >
      <SocialProfile
        key={`landingProfile-${deviceType}`}
        data={data}
        country={country}
        deviceType={deviceType}
        headerProps={headerProps}
        query={query}
        isWebView={isWebView}
        namespace={`${APP_PREFIX}-${namespace}`}
        melidataSocial={melidataSocial}
        metaTags={metaTags}
      />
    </LandingWrapper>
  );
};

export const hydrate = 'hydrateRoot';

export const getServerSideProps = async (req, res) => {
  const { auth, device, query } = req;
  const userId = auth?.user?.id;
  const { restclientFactory } = res.locals;
  const deviceType = device?.type || DEVICE_TYPE.DESKTOP;
  const domain = `https://www.${req.platform?.domain ?? 'mercadolivre.com.br'}`;
  const client = SOCIAL;

  query.device = deviceType;
  query.domain = domain;
  query.sub_path = req.path.replace('/social/', '');

  const data = await recosService.getLandingData(
    req,
    client,
    restclientFactory,
  );

  const domainToHeader = getDomain({
    domain: req.platform?.domain,
    devEnabled: true,
    nodeEnv: NODE_ENV,
  });

  const headerProps = {
    baseURL: `${domainToHeader}/api/recos-landings/`,
    buyer_id: userId?.toString(),
  };

  const { header } = data;

  const titlePage = header?.title?.text || 'Perfil Social';
  const metaTags = header?.meta_tags || {};
  const trackSocial = data.track?.page_view?.affiliates_event || {};

  return {
    props: {
      data,
      deviceType,
      canonical: req.canonical,
      country: countryConfig(req.platform.countryId),
      countryLocale: req.locale || req.i18n.locale,
      csrfToken: req.csrfToken(),
      headerProps,
      isWebView: req.device.webView,
      melidataSocial: trackSocial,
      query: req.query,
      siteId: req.platform.siteId,
      translations: req.translations,
      metaTags,
    },
    settings: {
      title: titlePage,
      melidata: getTrackView(data),
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  title: settings.title,
  melidata: settings.melidata,
});

SocialLanding.propTypes = {
  country: shape({}).isRequired,
  data: shape({}).isRequired,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  headerProps: shape({}).isRequired,
  query: shape({}).isRequired,
  isWebView: bool,
  melidataSocial: shape({}),
  metaTags: shape({}),
};

SocialLanding.defaultProps = {
  openInApp: { shouldRedirect: false },
  isWebView: false,
};

export default SocialLanding;
