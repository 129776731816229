import {
  trackPage as trackMelidataPage,
  trackEvent as trackMelidataEvent,
  trackEventWithExperiments as trackMelidataEventWithExperiments,
  trackView as trackMelidataView,
} from './melidata';

export const trackPage = (tracks) => {
  if (tracks && tracks.melidata_event) {
    trackMelidataPage(tracks.melidata_event);
  }
};

export const trackEvent = (tracks) => {
  if (tracks && tracks.melidata_event) {
    if (tracks.melidata_event.experiments) {
      trackMelidataEventWithExperiments(tracks.melidata_event);
    } else {
      trackMelidataEvent(tracks.melidata_event);
    }
  }
};

export const trackView = (tracks) => {
  if (tracks && tracks.event_data) {
    trackMelidataView({
      event_data: tracks.event_data,
      path: tracks.path,
      experiments: tracks.experiments || {},
    });
  }
};

if (global.window && global.window !== undefined) {
  global.window.Tracking = { trackPage, trackEvent };
}
