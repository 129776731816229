import { string, shape } from 'prop-types';
import React from 'react';

import { ProfileHeader } from 'eshops-components-library';

const FLOW_SOURCE = 'affiliates';

export const HeaderProfile = ({ namespace, header }) => (
  <div className={`${namespace}-header`}>
    <ProfileHeader {...header} flowSource={FLOW_SOURCE} lazyload />
  </div>
);

HeaderProfile.propTypes = {
  namespace: string,
  header: shape({}),
};
