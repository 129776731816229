import React from 'react';
import { oneOfType, element, shape } from 'prop-types';

import { EmptyStateWithIllustration } from '@andes/empty-state';
import { Button } from '@andes/button';

import { APP_PREFIX } from '@constants/commons';
import { arrayFind } from '@utils/array';
import StyledLabel from '@components/styled-label';
import TabEmptyStateIcon from '@components/icons/tab-empty-state';

import ButtonSearch from './button-search';

const EmptyStateContent = (props) => {
  const { components, emptyStateIcon: EmptyStateIcon } = props;
  const titleData = arrayFind(components, 'recos-empty-title');
  const subtitleData = arrayFind(components, 'recos-empty-subtitle');
  const btnSearchData = arrayFind(components, 'recos-empty-btn-search');
  const btnCartData = arrayFind(components, 'recos-empty-btn-cart');
  const Icon = EmptyStateIcon || TabEmptyStateIcon;

  return (
    <div className={`${APP_PREFIX}-empty-state-content`}>
      <EmptyStateWithIllustration
        title={
          titleData && (
            <StyledLabel
              as="span"
              dataModel={{
                ...titleData.title.label,
                values: titleData.title.values,
              }}
              className={`${APP_PREFIX}-title`}
              margin={{ bottom: 8 }}
            />
          )
        }
        description={
          subtitleData && (
            <StyledLabel
              as="span"
              dataModel={{
                ...subtitleData.subtitle.label,
                values: subtitleData.subtitle.values,
              }}
              className={`${APP_PREFIX}-subtitle`}
              margin={{ bottom: 32 }}
            />
          )
        }
        asset={<Icon />}
      />
      <div className={`${APP_PREFIX}-actions`}>
        {btnSearchData && (
          <ButtonSearch hierarchy="loud" action={btnSearchData.action} />
        )}
        {btnCartData && (
          <Button
            hierarchy="quiet"
            className={`${APP_PREFIX}-button-cart`}
            fullWidth
            type="link"
            href={btnCartData.action.target}
          >
            <StyledLabel as="span" dataModel={btnCartData.action.label} />
          </Button>
        )}
      </div>
    </div>
  );
};

EmptyStateContent.propTypes = {
  components: shape({}).isRequired,
  emptyStateIcon: oneOfType([element, null]),
};

export default EmptyStateContent;
