import { shape, string } from 'prop-types';
import React from 'react';

import { AffiliateFeeInfo } from 'eshops-components-library';

const DenounceProfile = ({ information, report_link, namespace }) => (
  <div className={`${namespace}-fee`}>
    <AffiliateFeeInfo information={information} report_link={report_link} />
  </div>
);

DenounceProfile.propTypes = {
  information: shape({}).isRequired,
  report_link: shape({}).isRequired,
  namespace: string.isRequired,
};

export default DenounceProfile;
