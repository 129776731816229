import fontSize from '@utils/classnames/font-size';
import fontColor from '@utils/classnames/font-color';
import fontFamily from '@utils/classnames/font-family';
import backgroundColor from '@utils/classnames/background-color';
import textAlign from '@utils/classnames/text-align';

const applyClasses = ({
  alignment,
  size,
  family,
  color,
  bg_color,
  className,
}) =>
  textAlign(
    alignment,
    backgroundColor(
      bg_color?.toUpperCase(),
      fontColor(color, fontSize(size, fontFamily(family, className))),
    ),
  );

export default applyClasses;
