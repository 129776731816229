import classnames from 'classnames';

const DEFAULT_COLOR = false;

const namespace = 'color';

const fontColor = (color, className) => {
  const colorName =
    typeof color === 'string'
      ? color.toUpperCase()
      : color?.color || DEFAULT_COLOR;

  return classnames({ [`${namespace}-${colorName}`]: colorName }, className);
};

export default fontColor;
