import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';

import { LAZYLOAD_OFF } from '@constants/commons';

const TabEmptyStateIcon = ({ alt }) => (
  <Image src="tab-empty-state.svg" alt={alt} preload lazyload={LAZYLOAD_OFF} />
);

TabEmptyStateIcon.propTypes = {
  alt: string,
};

export default TabEmptyStateIcon;
