import React from 'react';

import classnames from 'classnames';

import { APP_PREFIX } from '@constants/commons';
import Props from '@components/icons/props';

const namespace = `${APP_PREFIX}-icon ${APP_PREFIX}-icon-chevron`;
const ICON_ID = 'chevron_icon';

const IconChevron = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 9 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconChevron.propTypes = Props.propTypes;

const Icon = React.memo(IconChevron);

Icon.ICON_ID = ICON_ID;

export default Icon;
