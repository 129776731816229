import React from 'react';
import { string, node, shape } from 'prop-types';

import classnames from 'classnames';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import { AndesProvider } from '@andes/context';

import ErrorBoundary from '@components/error-boundary';
import { SVG_SPRITES, APP_PREFIX } from '@constants/commons';

const LandingWrapper = (props) => {
  const { client, namespace, className, children, csrfToken, country } = props;

  return (
    <div
      className={classnames(
        `${APP_PREFIX} ${APP_PREFIX}-${namespace}`,
        className,
      )}
    >
      <Hydrator ssrOnly>{SVG_SPRITES}</Hydrator>
      <AndesProvider
        locale={country?.locale?.replace('_', '-')}
        csrfToken={csrfToken}
      >
        <ErrorBoundary client={client}>{children}</ErrorBoundary>
      </AndesProvider>
    </div>
  );
};

LandingWrapper.propTypes = {
  client: string,
  namespace: string,
  className: string,
  children: node,
  csrfToken: string,
  country: shape({
    locale: string,
  }),
};

export default LandingWrapper;
