import React from 'react';
import { shape, string } from 'prop-types';

import { Button } from '@andes/button';
import { Card, CardContent } from '@andes/card';

import StyledLabel from '@components/styled-label';
import IconFull from '@components/icons/full';
import IconFactoryModule from '@components/icons/factory-builder';
import { APP_PREFIX } from '@constants/commons';

const IconFactory = IconFactoryModule([IconFull]);

const DEFAULT_HIERARCHY = 'quiet';
const namespace = `${APP_PREFIX}-button-search`;

const handlers = {
  handleButtonSearchClick: (action) => {
    if (action.target) {
      if (action.target.startsWith('meli://suggestions')) {
        // Android
        window.MobileWebKit.navigation
          .push(encodeURI(action.target), {
            transition: 'present',
          })
          .catch(() => {});
      } else {
        window.MobileWebKit.executeNative({
          // iOS
          method: 'open_search_modal',
          args: {
            deepLink: encodeURI(action.target),
          },
        }).catch(() => {});
      }
    } else if (window.freya) {
      window.freya.emit('searchbox:focus');
    }
  },
};

const ButtonSearch = ({
  title,
  subtitle,
  action,
  hierarchy = DEFAULT_HIERARCHY,
}) => (
  <div className={namespace}>
    <Card id="card-button-search">
      <CardContent>
        {title && (
          <StyledLabel
            as="p"
            dataModel={{ ...title.label, values: title.values }}
            className={`${APP_PREFIX}-title`}
            iconFactory={IconFactory}
          />
        )}
        {subtitle && (
          <StyledLabel
            as="p"
            dataModel={{ ...subtitle.label, values: subtitle.values }}
            className={`${APP_PREFIX}-subtitle`}
            margin={{ top: 5 }}
          />
        )}
        <Button
          id="button-search"
          hierarchy={hierarchy}
          className={`${APP_PREFIX}-button`}
          fullWidth
          type="button"
          onClick={handlers.handleButtonSearchClick(action)}
        >
          <StyledLabel as="span" dataModel={action.label} />
        </Button>
      </CardContent>
    </Card>
  </div>
);

ButtonSearch.propTypes = {
  title: shape(),
  subtitle: shape(),
  action: shape(),
  hierarchy: string,
};

export default ButtonSearch;
