import IconFactory from '@recommendations-fe/icons/factory-builder';

import {
  IconRecommendationsFull,
  IconRecommendationsMeliCoins,
  IconRecommendationsChevron,
} from './icon-generator';

const RecommendationsIconFactory = IconFactory([
  IconRecommendationsFull,
  IconRecommendationsMeliCoins,
  IconRecommendationsChevron,
]);

export default RecommendationsIconFactory;
