import React from 'react';
import { string, bool, shape } from 'prop-types';

import RecoomendationsFree from '@components/recommendations/carousel-free';
import { APP_PREFIX } from '@constants/commons';

const namespace = `${APP_PREFIX}-carousel-featured`;

const CarouselFeatured = ({
  recommendation_data,
  is_polycard,
  country,
  deviceType,
  title,
  subtitle,
}) => (
  <RecoomendationsFree
    className={namespace}
    recommendation_data={recommendation_data}
    country={country}
    is_polycard={is_polycard}
    deviceType={deviceType}
    title={title}
    subtitle={subtitle}
    lazy={false}
  />
);

CarouselFeatured.propTypes = {
  recommendation_data: shape({}),
  is_polycard: bool,
  country: shape({}),
  deviceType: string,
  title: string,
  subtitle: shape({}),
};

export default CarouselFeatured;
