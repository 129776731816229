import React from 'react';
import { object, oneOf, oneOfType, array, string, shape } from 'prop-types';

import classnames from 'classnames';
import { RecommendationsCarouselSnapped } from '@recommendations-fe/carousel-snapped';

import { arrayFind } from '@utils/array';
import { CARD_FEATURED, CAROUSEL_FEATURED } from '@constants/components-ids';
import { DEFAULT_SLIDE_PER_VIEW } from '@constants/recommendations';
import { DEVICE_TYPE } from '@constants/commons';
import CardFeatured from '@components/card-featured';
import IconFactory from '@components/recommendations/icons/icon-factory';
import CarouselFeatured from '@components/carousel-featured';

export const CarouselSnappedSocial = (props) => {
  const { recommendation_data: recommendationData, namespace } = props;

  const newSlidesPerView =
    recommendationData?.recommendation_info?.polycards?.length <
    DEFAULT_SLIDE_PER_VIEW
      ? recommendationData?.recommendation_info?.polycards.length
      : DEFAULT_SLIDE_PER_VIEW;

  const cardProps = {
    polyClassName: 'custom-polycard-classname',
  };

  const carouselClassname = classnames(`${namespace}__slide`, {
    less: newSlidesPerView < DEFAULT_SLIDE_PER_VIEW,
  });

  return (
    <div className={`${namespace}__carousel`}>
      <div className="story-recommendations-container">
        <RecommendationsCarouselSnapped
          IconFactory={IconFactory}
          key="carousel-snapped-social"
          cardProps={cardProps}
          deviceType="desktop"
          cardType="grid-card"
          apiresponse={recommendationData}
          isPolycard
          slidesPerView={newSlidesPerView}
          className={carouselClassname}
          {...props}
        />
      </div>
    </div>
  );
};

CarouselSnappedSocial.propTypes = {
  recommendation_data: oneOfType([object, array]),
  namespace: string,
  slides_per_view: string,
};

const getPropsCardFeatured = (tiggerCard, namespace, country, deviceType) => {
  const actionButton = tiggerCard?.subtitle
    ? {
        label: tiggerCard.subtitle.text,
        url: tiggerCard.subtitle.url,
      }
    : tiggerCard?.action;

  const cardProps = {
    ...tiggerCard,
    actionButton,
    namespace,
    country,
    deviceType,
  };

  return cardProps;
};

export const RecommendationsProductSocial = (props) => {
  const { components, deviceType, namespace, country } = props;
  const tiggerCard = arrayFind(components, CARD_FEATURED);
  const carouselHead = arrayFind(components, CAROUSEL_FEATURED);

  const CarouselComponent =
    deviceType === DEVICE_TYPE.DESKTOP
      ? CarouselSnappedSocial
      : CarouselFeatured;

  if (!tiggerCard && !carouselHead) {
    return null;
  }

  const cardProps = getPropsCardFeatured(
    tiggerCard,
    namespace,
    country,
    deviceType,
  );

  return (
    <>
      {tiggerCard && <CardFeatured key="polycard-social" {...cardProps} />}
      {carouselHead && (
        <CarouselComponent
          key="carousel-social"
          {...carouselHead}
          deviceType={deviceType}
          country={country}
          namespace={namespace}
        />
      )}
    </>
  );
};

RecommendationsProductSocial.propTypes = {
  components: oneOfType([object, array]),
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  namespace: string,
  country: shape({}),
};
