// React & Dependencies
import React from 'react';
import { string, bool, shape, func } from 'prop-types';

import classNames from 'classnames';
import { RecommendationsCarouselFree } from '@recommendations-fe/carousel-free';

import IconFactory from '@components/recommendations/icons/icon-factory';
import { APP_PREFIX, DEVICE_TYPE } from '@constants/commons';
import { CARD_TYPE } from '@constants/recommendations';

const namespace = `${APP_PREFIX}-carousel-free`;

const RecommendationsFeFree = ({
  className,
  recommendation_data,
  is_polycard = true,
  lazy = false,
  deviceType = DEVICE_TYPE.MOBILE,
  handleSaveRenderTrack = null,
  shouldTrackOnPrint,
  shouldTrackOnView,
  ...rest
}) => (
  <section className={classNames(namespace, className)}>
    <RecommendationsCarouselFree
      IconFactory={IconFactory}
      isPolycard={is_polycard}
      paddingHorizontal={8}
      apiresponse={recommendation_data}
      lazy={lazy}
      deviceType={deviceType}
      onTrack={handleSaveRenderTrack}
      shouldTrackOnPrint={shouldTrackOnPrint}
      shouldTrackOnView={shouldTrackOnView}
      cardType={CARD_TYPE.GRID}
      {...rest}
    />
  </section>
);

RecommendationsFeFree.propTypes = {
  className: string,
  recommendation_data: shape({}),
  is_polycard: bool,
  lazy: bool,
  deviceType: string,
  handleSaveRenderTrack: func,
  shouldTrackOnPrint: bool,
  shouldTrackOnView: bool,
};

export default RecommendationsFeFree;
