import classnames from 'classnames';

const DEFAULT_SIZE = false;

const namespace = 'font-size';

const fontSize = (size, className) => {
  const sizeName =
    typeof size === 'string' ? size : size?.font_size || DEFAULT_SIZE;

  return classnames({ [`${namespace}-${sizeName}`]: sizeName }, className);
};

export default fontSize;
