import classnames from 'classnames';

const DEFAULT_BG_COLOR = false;

const namespace = 'background-color';

const backgroundColor = (bgColor, className) => {
  const colorName =
    typeof bgColor === 'string'
      ? bgColor
      : (bgColor && (bgColor.bgColor || bgColor.background_color)) ||
        DEFAULT_BG_COLOR;

  return classnames({ [`${namespace}-${colorName}`]: colorName }, className);
};

export default backgroundColor;
