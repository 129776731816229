export const CARD_FEATURED = 'card-featured';
export const CAROUSEL_FEATURED = 'carousel-featured';
export const DENOUNCE_PROFILE = 'denounce-profile';
export const EMPTY_BUTTON_CART = 'recos-empty-btn-cart';
export const EMPTY_BUTTON_SEARCH = 'recos-empty-btn-search';
export const EMPTY_SUBTITLE = 'recos-empty-subtitle';
export const EMPTY_STATE = 'recos-empty-state';
export const EMPTY_TITLE = 'recos-empty-title';
export const ERROR_HANDLED = 'recos-error-handled';
export const HEADER_PROFILE = 'affiliate-profile-header';
export const MLT_RECOS = 'more-like-this-recommendations';
export const PADS_OFF_RECOS = 'pads-off-recommendations';
export const REVIEWS = 'reviews';
export const STORE_FRONT_INFORMATION_ID = 'store-front-information';
export const SUBTITLE = 'cart-subtitle';
export const TABS_RECOS = 'tabs';
export const VIEW_MORE_RECOS = 'view-more-recommendations';
export const HEADER_ID = 'header-id';
export const DCA_RECOS_ID = 'display-catalog-ads-recommendations';
export const PERSO_RECOS_ID = 'perso-landing';
export const BUTTON_LANDING = 'landing-button';
export const REDIRECT_PADS_OFF = 'redirect-pads-off-item';
export const HOME_RECOS_ID = 'home-landing-recommendations';
