import React from 'react';

import warning from 'warning';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isMap from 'lodash/isMap';

import colornames from '../../utils/classnames/font-color';

const IS_DEV = process.env.NODE_ENV !== 'production';
const BAD_PARAM_ERROR = 'Bad Parameter, array expected';

const getIconComponent = (id, iconSet) => get(iconSet, id);

const buildComponent = (Component, props) => (
  <Component key={Component.ICON_ID} {...props} />
);

const IconFactory = (iconSet) => {
  const icons = iconSet.reduce((acc, i) => {
    acc[i.ICON_ID] = i;

    return acc;
  }, {});

  return (icon, propsOrclassName) => {
    let className;
    let props = {};

    if (typeof propsOrclassName === 'string') {
      className = propsOrclassName;
    } else if (isObject(propsOrclassName) || isMap(propsOrclassName)) {
      if (propsOrclassName?.className) {
        className = propsOrclassName.className;
      }

      props = propsOrclassName;
    }

    if (React.isValidElement(icon)) {
      return React.cloneElement(icon, { className });
    }

    if (!isArray(iconSet)) {
      throw new Error(BAD_PARAM_ERROR);
    }

    let id;

    if (typeof icon === 'string') {
      id = icon.toLowerCase();
    } else if (isObject(icon) && typeof icon.id === 'string') {
      id = icon.id.toLowerCase();
    } else {
      if (IS_DEV) {
        warning(
          false,
          `icon must be an string or an object with id property, ${typeof icon} provided`,
        );
      }

      return null;
    }

    const Component = getIconComponent(id, icons);

    if (!Component) {
      if (IS_DEV) {
        warning(false, `icon "${id}" not found`);
      }

      return null;
    }

    return Component
      ? buildComponent(Component, {
          ...props,
          className: colornames(icon, className),
          description: icon.description,
          accessibilityText: icon.accessibilityText,
        })
      : Component;
  };
};

const iconFactoryFunction = (iconSet) => IconFactory(iconSet);

export default iconFactoryFunction;
