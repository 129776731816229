import React from 'react';

import { Image } from 'nordic/image';

import Props from '@components/icons/props';

const IconMeliCoins = ({ className = null }) => (
  <div className={className} width="15" height="15" alt="Melicoin">
    <Image src="melicoin.svg" alt="" />
  </div>
);

IconMeliCoins.propTypes = Props.propTypes;

export default React.memo(IconMeliCoins);
