const objectEntries = (obj) => {
  const ownProps = Object.keys(obj);

  let i = ownProps.length;

  const resArray = new Array(i);

  while (i > 0) {
    i -= 1;
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
  }

  return resArray;
};

export { objectEntries };
