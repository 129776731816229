import IconFull from '@components/icons/full';
import IconMeliCoins from '@components/icons/meli-coins';
import IconChevron from '@components/icons/chevron';

export const IconRecommendationsFull = {
  ...IconFull,
  ICON_ID: 'recommendations-full_icon',
};
export const IconRecommendationsMeliCoins = {
  ...IconMeliCoins,
  ICON_ID: 'recommendations-meli_coins',
};
export const IconRecommendationsChevron = {
  ...IconChevron,
  ICON_ID: 'recommendations-chevron',
};
