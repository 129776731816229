/* eslint-disable react/prop-types */
import React from 'react';

import classnames from 'classnames';
import { Icons } from '@polycard/icons';

import { APP_PREFIX, DEVICE_TYPE } from '@constants/commons';
import { REVIEWS } from '@constants/components-ids';
import { CARD_TYPE } from '@constants/recommendations';
import { arrayFind } from '@utils/array';
import IconFactory from '@components/icons/factory-builder';
import RecommendationsFeSingle from '@components/recommendations/recommendations-single';

const namespace = `${APP_PREFIX}-card-featured`;
const CardFeatured = (props) => {
  const {
    recommendation_data: { recommendation_info: recommendationInfo },
    deviceType,
    country,
  } = props;

  if (!recommendationInfo) {
    return null;
  }

  const reviews = arrayFind(
    recommendationInfo.polycards[0].components,
    REVIEWS,
  );

  return (
    <section className={classnames(namespace)}>
      <RecommendationsFeSingle
        IconFactory={IconFactory}
        recommendation_data={{
          recommendation_info: recommendationInfo,
        }}
        country={country}
        cardType={CARD_TYPE.LIST}
        deviceType={deviceType}
      />
      {reviews && (
        <Icons icons={['poly_star_half', 'poly_star_fill', 'poly_star']} />
      )}
    </section>
  );
};

CardFeatured.defaultProps = {
  cardType: CARD_TYPE.SINGLE,
  deviceType: DEVICE_TYPE.MOBILE,
};

export default CardFeatured;
