/* global melidata */

/**
 * Page Tracker
 *
 * @param {String} path
 * @param {Object} dimensions
 * @param {Object} experiments
 * @param {Object} data
 *
 * @example
 * trackPage({
 *     path: '/home/test/',
 *     data: {
 *         dimension1: 'dimension1',
 *     },
 * });
 */
export function trackPage({
  path = null,
  experiments = null,
  event_data = {},
} = {}) {
  // If melidata is not defined do nothing for now
  if (typeof melidata !== 'function') {
    return;
  }

  // If one of this parameters is empty do not track
  if (!path) {
    return;
  }

  const track = {};

  melidata('createViewTrack', track);
  melidata('withPath', path, track);

  if (event_data) {
    melidata('withData', event_data, track);
  }

  if (experiments) {
    melidata('withExperiments', experiments, track);
  }

  melidata('sendTrack', track);
}

/**
 * Event Tracker
 *
 * @param {String} path
 * @param {String} data
 * @param {String} clean
 *
 * If you send clean = true the event is going to change to cleanAndSend
 *
 * @example
 * trackEvent({
 *     path: '/cart/my_cart/',
 *     data: {},
 * });
 */
export function trackEvent({ path = null, event_data = null } = {}) {
  // If melidata is not defined do nothing for now
  if (typeof melidata !== 'function') {
    return;
  }

  if (!path) {
    return;
  }

  const actionType = 'cleanAndSend';

  if (event_data) {
    melidata(actionType, 'event', {
      path,
      event_data,
    });
  } else {
    melidata(actionType, 'event', {
      path,
    });
  }
}

export function trackEventWithExperiments({
  path = null,
  event_data = null,
  experiments = null,
} = {}) {
  // If melidata is not defined do nothing for now
  if (typeof melidata !== 'function') {
    return;
  }

  if (!path) {
    return;
  }

  const track = {};

  melidata('createEventTrack', track);
  melidata('withPath', path, track);

  if (event_data) {
    melidata('withData', event_data, track);
  }

  if (experiments) {
    melidata('withExperiments', experiments, track);
  }

  melidata('sendTrack', track);
}

export function trackView({
  path = null,
  event_data = null,
  experiments = null,
} = {}) {
  // If melidata is not defined do nothing for now
  if (typeof melidata !== 'function') {
    return;
  }

  if (!path) {
    return;
  }

  const actionType = 'cleanAndSend';

  if (event_data) {
    melidata(actionType, 'view', {
      path,
      event_data,
      experiments,
    });
  } else {
    melidata(actionType, 'view', {
      path,
    });
  }
}
