// Prefixes
export const APP_PREFIX = 'rl';

// Devices
export const DEVICE_TYPE = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

// Lazyload
export const LAZYLOAD_ON = 'on';
export const LAZYLOAD_OFF = 'off';

// Icons
export const SVG_SPRITES = 'svg-sprites-icons';

// Utils
export const INTERPOLATION_REGEXP = /{([\s\S]+?)}/g;
