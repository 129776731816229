export const DYNAMIC_ACCESS = 'dynamic-access';
export const MLT = 'mlt';
export const PADS = 'pads';
export const PADS_OFF = 'pads-off';
export const SOCIAL = 'affiliate-profile';
export const VIEW_MORE = 'view-more';
export const PERSO = 'perso';
export const TABS = 'tabs';
export const DCA = 'display-catalog-ads';
export const BAR = 'buy-again-recurrent';
