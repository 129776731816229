import React from 'react';

import classnames from 'classnames';

import { APP_PREFIX } from '@constants/commons';
import Props from '@components/icons/props';

const namespace = `${APP_PREFIX}-icon ${APP_PREFIX}-icon-hand-shake`;
const ICON_ID = 'hand_shake_icon';

const IconHandShake = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    width="19"
    height="13"
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="svg-image"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconHandShake.propTypes = Props.propTypes;

const Icon = React.memo(IconHandShake);

Icon.ICON_ID = ICON_ID;

export default Icon;
